import { Outlet } from "@remix-run/react";
import { Footer } from "~/components/Footer";

export default function LandingLayout() {
  return (
    <>
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
